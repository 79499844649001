import { getUserTier } from "@/utils/CustomerTiersUtils";

export class CustomerMetadata {
  setData(response) {
    this.customerId = response.customerId;
    this.registrationDate = response.registrationDate;
    this.expirationDate = response.expirationDate;
    this.tier = getUserTier(response.tier);
    this.fullName = response.fullName;
    this.organisation = response.organisation;
    this.comments = response.comments;
    this.email = response.email;
    this.measurementSystem = response.measurementSystem;
    return this;
  }

  getId() {
    return this.customerId;
  }

  getRegistrationDate() {
    return this.registrationDate;
  }

  getExpirationDate() {
    return this.expirationDate;
  }

  getTier() {
    return this.tier;
  }

  getEmail() {
    return this.email;
  }
}
