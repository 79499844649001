<script>
import routes from '../router/routes'
export default {
  name: "ReturnButton",
  data() {
    return {
      showBackButton: false,
    };
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
  watch: {
    $route(to, from) {
      const dashboardRoute = routes.find((route) => route.name === 'dashboard').path;
      const feedbackRoute = routes.find((route) => route.name === 'feedback').path;
      this.showBackButton = from.name !== null && to.path !== dashboardRoute && to.path !== feedbackRoute;
    },
  },
}
</script>

<template>
  <button
      class="fixed top-[105px] left-2 bg-white rounded-full px-12 py-1.5 text-white text-md z-10 flex items-center justify-center"
      style="background-color: #0072ce"
      @click="goBack"
      v-if="showBackButton">
    <span class="absolute left-2 flex justify-center items-center text-4xl mb-1 font-thin">&lt;</span>
    Back
  </button>
</template>

<style scoped>

</style>