export const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_Dashboard_Users",
    editProfile: "B2C_1_Edit_profile"
  },
  authorities: {
    signUpSignIn: {
      authority: "https://precisionsportstech.b2clogin.com/precisionsportstech.onmicrosoft.com/B2C_1_Dashboard_Users",
    },
    editProfile: {
      authority: "https://precisionsportstech.b2clogin.com/precisionsportstech.onmicrosoft.com/B2C_1_Edit_profile"
    }
  },
  authorityDomain: "https://precisionsportstech.b2clogin.com"
}