import * as signalR from "@microsoft/signalr";
import { tokenRequest } from "@/msal/authConfig";
import { getTokenRedirect } from "@/msal/authRedirect";
import { apiConfig } from "@/msal/apiConfig";

class SignalRService {
  constructor() {
    this.connection = null;
    this.token = null;
  }

  /**
   * Initializes the SignalR connection.
   * @returns {Promise<signalR.HubConnection>} The initialized SignalR connection.
   * @throws Will throw an error if the connection fails to start.
   */
  async initialiseConnection() {
    if (this.connection) {
      return this.connection;
    }

    this.token = await getTokenRedirect(tokenRequest);
    const bearer = `Bearer ${this.token}`;


    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(apiConfig.apiUrl + "/notificationHub", {
        headers: {
          Authorization: bearer,
        },
        transport: signalR.HttpTransportType.LongPolling,
      })
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Debug)
      .build();

    try {
      await this.connection.start();
    } catch (err) {
      this.connection = null;
      console.error(err);
      throw err;
    }
    return this.connection;
  }
}

const signalRService = new SignalRService();
export default signalRService;