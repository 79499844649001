/**
 * ATHLETE SORT UTILS
 */

/**
 * Sorts athletes by the most recent assessment timestamp.
 * @param athletes - list of athletes
 * @param assessments - list of assessments
 * @returns {Array} - sorted list of athletes
 */
export const sortAthletesByAssessment = function (athletes, assessments) {
  // Collect the most recent assessment timestamp for each athlete
  const athleteAssessmentTimestamps = new Map();

  assessments.forEach((assessment) => {
    const athleteId = assessment.athleteId;

    if (
      assessment.timestamp &&
      (!athleteAssessmentTimestamps.has(athleteId) ||
        assessment.timestamp > athleteAssessmentTimestamps.get(athleteId))
    ) {
      athleteAssessmentTimestamps.set(athleteId, assessment.timestamp);
    }
  });

  // Sort athletes based on most recent assessment timestamps
  athletes.sort((a, b) => {
    const timestampA = athleteAssessmentTimestamps.get(a.athleteId);
    const timestampB = athleteAssessmentTimestamps.get(b.athleteId);

    if (timestampA && timestampB) {
      return timestampB.localeCompare(timestampA);
    } else if (timestampA) {
      return -1;
    } else if (timestampB) {
      return 1;
    }

    return 0;
  });

  return athletes;
};

/**
 * Sorts athletes by first name, then by last name.
 * @param athletes - list of athletes
 * @returns {Array} - sorted list of athletes
 */
export const sortAthletesByFirstName = function (athletes) {
  if (!athletes) {
    return athletes;
  }

  athletes.sort((a, b) => {
    if (a.firstName.localeCompare(b.firstName) == 0) {
      if (
        (!a.lastName || a.lastName == "") &&
        (!b.lastName || b.lastName == "")
      ) {
        // If both last names are null or empty they're equal
        return 0;
      } else if (!a.lastName || a.lastName == "") {
        // If only a's last name is null or empty, it comes after b
        return 1;
      } else if (!b.lastName || b.lastName == "") {
        // If only b's last name is null or empty, it comes before a
        return -1;
      } else {
        // Sort by last name, then by first name
        const lastNameComparison = a.lastName.localeCompare(b.lastName);
        if (lastNameComparison === 0) {
          // If last names are the same, sort by first name
          return a.firstName.localeCompare(b.firstName);
        }
        return lastNameComparison;
      }
    }
    return a.firstName.localeCompare(b.firstName);
  });

  return athletes;
};

/**
 * Sorts athletes by last name, then by first name.
 * @param athletes - list of athletes
 * @returns {Array} - sorted list of athletes
 */
export const sortAthletesByLastName = function (athletes) {
  if (!athletes) {
    return athletes;
  }
  athletes.sort((a, b) => {
    // Handle null and empty last names
    if (
      (!a.lastName || a.lastName == "") &&
      (!b.lastName || b.lastName == "")
    ) {
      // If both last names are null or empty, sort by first name
      return a.firstName.localeCompare(b.firstName);
    } else if (!a.lastName || a.lastName == "") {
      // If only a's last name is null or empty, it comes after b
      return 1;
    } else if (!b.lastName || b.lastName == "") {
      // If only b's last name is null or empty, it comes before a
      return -1;
    } else {
      // Sort by last name, then by first name
      const lastNameComparison = a.lastName.localeCompare(b.lastName);
      if (lastNameComparison === 0) {
        // If last names are the same, sort by first name
        return a.firstName.localeCompare(b.firstName);
      }
      return lastNameComparison;
    }
  });

  return athletes;
};

/**
 * Sorts athletes by date of birth.
 * @param athletes - list of athletes
 * @returns {Array} - sorted list of athletes
 */
export const sortAthletesByAge = function (athletes) {
  if (!athletes) {
    return athletes;
  }
  athletes.sort((a, b) => {
    if (a.dob && b.dob) {
      return new Date(b.dob) - new Date(a.dob);
    } else if (a.dob) {
      return -1;
    } else if (b.dob) {
      return 1;
    }
    return 0;
  });

  return athletes;
};
