import { InteractionStatus } from "@azure/msal-browser";
import { getCurrentInstance, onMounted, ref, toRefs } from "vue";
import { Exception } from "sass";
import { tokenRequest } from "@/msal/authConfig";

export function useMsal() {
  const internalInstance = getCurrentInstance();
  if (!internalInstance) {
    throw new Exception(
      "useMsal() cannot be called outside the setup() function of a component"
    );
  }
  const { instance, accounts, inProgress } = toRefs(
    internalInstance.appContext.config.globalProperties.$msal
  );

  if (!instance.value || !accounts.value || !inProgress.value) {
    throw new Exception("Please install the msalPlugin");
  }

  if (inProgress.value === InteractionStatus.Startup) {
    instance.value.handleRedirectPromise().catch((reason) => {
      // Errors should be handled by listening to the LOGIN_FAILURE event
      console.error("Startup error:", reason);
    });
  }

  const accessToken = ref("");

  const getToken = async () => {
    try {
      if (!accounts.value.length) {
        console.warn("No accounts detected. Please sign in.");
        return;
      }
      accessToken.value = await instance.value.acquireTokenSilent(tokenRequest);
    } catch (error) {
      // Handle token acquisition errors
      console.error("Failed to acquire token:", error);
    }
  };

  // Get token on component mount
  onMounted(async () => {
    await getToken();
  });

  return {
    instance: instance.value,
    accounts,
    inProgress,
    accessToken,
    getToken,
  };
}
