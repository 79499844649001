import { defineStore } from "pinia";

export const useProfileDataStore = defineStore("profileDataStore", {
    state: () => {
    return {
      profileString: "",
    }
  },
  actions: {
    updateProfileString() {
      this.profileString = Date.now().toString();
    }
  },
});