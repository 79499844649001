import { b2cPolicies } from "@/msal/b2cPolicies";
import { apiConfig } from "@/msal/apiConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { useMsal } from "@/msal/useMsal";
import { LogLevel } from "@microsoft/signalr";

const EXPIRATION_TIME = 60 * 60 * 1000; // 1 hour

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * For more details on using MSAL.js with Azure AD B2C, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md
 */
export const msalConfig = {
  auth: {
    clientId: "2f9aaa4e-3025-4f82-8cdc-c4fd3192108d",
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    // An error occurs where the window may be undefined when running tests. 
    // this is a workaround to prevent the error
    postLogoutRedirectUri: 
      (typeof window !== 'undefined' && window.location.host.includes("localhost") ? "http://" : "https://") +
      (typeof window !== 'undefined' ? window.location.host : "localhost") + "/login",
  },
  cache: {
    cacheLocation: "localStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
      loggerOptions: {
          loggerCallback: (level, message, containsPii) => {
              if (containsPii) {
                  return;
              }
              switch (level) {
                  case LogLevel.Error:
                      console.error(message);
                      return;
                  case LogLevel.Info:
                      console.info(message);
                      return;
                  case LogLevel.Verbose:
                      console.debug(message);
                      return;
                  case LogLevel.Warning:
                      console.warn(message);
                      return;
                  default:
                      return;
              }
          },
          logLevel: LogLevel.Verbose
      }
  }
};

export const msalInstance = new PublicClientApplication(msalConfig);
msalInstance.initialize();

export const loginRequest = {
  scopes: ["openid", "offline_access", ...apiConfig.b2cScopes],
};

export const tokenRequest = {
  scopes: [...apiConfig.b2cScopes],
  forceRefresh: false,
};

/**
 * Silently acquires an access token for the given scopes from the authority
 * @returns {Promise<string>} A promise that resolves to an access token
 */
const acquireTokenSilent = async () => {
  try {
    const silentResult = await msalInstance.acquireTokenSilent(tokenRequest);
    return silentResult?.accessToken;
  } catch (error) {
    console.error(
      "Silent token acquisition failed. User interaction required.",
      error
    );
    throw error;
  }
};

/**
 * Checks whether there is a cached token for this request, and if conditions are met, gets a new token from Azure AD B2C.
 * @returns {Promise<null|string>} A promise that resolves to a token, or null if no token could be fetched
 */
export const renewToken = async () => {
  const expiration = msalInstance.getActiveAccount()?.idTokenClaims.exp || 0;
  const currentTime = new Date().getTime();
  const timeUntilExpiration = expiration - currentTime;

  // Log token expiration details
  // let's keep logging these until we're sure this is working pls
  console.log("Token expiration:", expiration);
  console.log("Current time:", currentTime);
  console.log("Time until expiration:", timeUntilExpiration);
  console.log("Expiration time:", EXPIRATION_TIME);
  // Renew token if it's about to expire or has already expired
  if (
    expiration !== 0 &&
    useMsal()?.accessToken &&
    timeUntilExpiration < EXPIRATION_TIME
  ) {
    try {
      const renewedToken = await acquireTokenSilent();

      if (!renewedToken) {
        throw new Error("Failed to renew token.");
      }

      console.log("Token renewed successfully.");
      return renewedToken;
    } catch (error) {
      console.error("Token renewal failed.", error);
      throw error;
    }
  }

  // return current token
  return useMsal()?.accessToken;
};

/**
 * Calls acquireTokenSilent to get a token for the given scopes from the authority.
 * @returns {Promise<string>} A promise that resolves to an access token
 */
export const getAccessToken = async () => {
  try {
    if (!msalInstance.getActiveAccount()) {
      throw new Error(
        "User account missing from MSAL instance. Please sign out and sign in again."
      );
    }

    const cachedToken = await msalInstance.acquireTokenSilent(tokenRequest);

    if (!cachedToken) {
      throw new Error("Token acquisition failed.");
    }

    return cachedToken.accessToken;
  } catch (silentError) {
    console.error(
      "Silent token acquisition failed. Acquiring token using redirect.",
      silentError
    );
    // If silent acquisition fails, try to renew the token
    const renewedToken = await renewToken();
    if (renewedToken) {
      return renewedToken.accessToken;
    }

    // If renewal also fails, redirect to login page or handle appropriately
    // e.g., redirect to login page or show an error message
    throw silentError;
  }
};
