import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import { createRouter, createWebHistory } from "vue-router";
import routes from "./router/routes";
import "./assets/tailwind.css";
import "./assets/sass/style.scss"; //Font sheet
import "./assets/reset.css"; //Stylesheet
import { msalPlugin } from "./plugins/msalPlugin";
import { msalInstance } from "./msal/authConfig";
import { EventType } from "@azure/msal-browser";
import { registerGuard } from "./router/Guard";
import { CustomNavigationClient } from "./router/NavigationClient";
import { datadogRum } from "@datadog/browser-rum";
import { useNavBarOptionsStore } from "@/stores/NavBarOptionsStore";

const isLocalhost = window.location.hostname === 'localhost';

if (!isLocalhost) {
  datadogRum.init({
    applicationId: process.env.VUE_APP_DD_APPID,
    clientToken: process.env.VUE_APP_DD_CLIENT_TOKEN,
    site: "datadoghq.eu",
    service: "kinetikiq-dashboard",
    env: "prod",
    allowedTracingUrls: ["https://kinetikiq.com", (url) => url.startsWith("https://kinetikiq.com"), (url) => url.startsWith("https://www.kinetikiq.com"), (url) => url.startsWith("https://staging-pst.azurefd.net"), (url) => url.startsWith("https://kinetikiq.azurefd.net")],
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask",
  });
}

const router = createRouter({
  history: createWebHistory(),
  routes,
});
registerGuard(router);

const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}
msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload; //as AuthenticationResult;
    const account = payload.account;
    msalInstance.setActiveAccount(account);
  }
});

const pinia = createPinia();
const app = createApp(App);

app.use(pinia);
app.use(router);
app.use(msalPlugin, msalInstance);

const navbarOptionsStore = useNavBarOptionsStore();
router.beforeEach((to, from, next) => {
  if (to.meta.selectedNavbarItem) {
    navbarOptionsStore.setSelected(to.meta.selectedNavbarItem);
  }
  next();
});

router.isReady().then(() => {
  // Waiting for the router to be ready prevents race conditions when returning from a loginRedirect or acquireTokenRedirect
  app.mount("#app");
});
