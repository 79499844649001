import { msalInstance, tokenRequest } from "@/msal/authConfig";
import { UserCategories } from "@/models/UserCategoriesEnum";
import { ref } from "vue";

/**
 * Prettifies the user category based on the active account's token claims.
 * @returns {Promise<string|null>} The prettified user category or null if no active account.
 */
export async function prettifyUserCategory() {
  if (!msalInstance.getActiveAccount()) return null;
  await msalInstance.acquireTokenSilent(tokenRequest);
  switch (msalInstance.getActiveAccount().idTokenClaims.extension_Category) {
    case UserCategories.Coach.valueOf():
      return "Coach";
    case UserCategories.Physio.valueOf():
      return "Physiotherapist";
    case UserCategories.Admin.valueOf():
      return "Administrator";
    default:
      return msalInstance.getActiveAccount().idTokenClaims.extension_Category;
  }
}

/**
 * @typedef {Object} UserType
 * @property {string} capitalised - The capitalised form of the user type.
 * @property {string} lowercase - The lowercase form of the user type.
 * @property {string} capitalisedPlural - The capitalised plural form of the user type.
 * @property {string} lowercasePlural - The lowercase plural form of the user type.
 */

/** @type {UserType} */
const Athlete = {
  capitalised: "Athlete",
  lowercase: "athlete",
  capitalisedPlural: "Athletes",
  lowercasePlural: "athletes",
};

/** @type {UserType} */
const Patient = {
  capitalised: "Patient",
  lowercase: "patient",
  capitalisedPlural: "Patients",
  lowercasePlural: "patients",
};

/** @type {import('vue').Ref<UserType>} */
export const athleteOrPatient = ref(Athlete);

/** @type {import('vue').Ref<string>} */
export const metricOrImperial = ref("metric");

/**
 * Updates the token claims and sets the athleteOrPatient and metricOrImperial values.
 * @returns {Promise<void>}
 */
export async function updateTokenClaims() {
  if (!msalInstance.getActiveAccount()) {
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    } else return;
  }
  await msalInstance.acquireTokenSilent(tokenRequest);
  athleteOrPatient.value = (await isUserPhysio()) ? Patient : Athlete;
  metricOrImperial.value = (await isImperial()) ? "imperial" : "metric";
}

/**
 * Checks if the active account uses the imperial measurement system.
 * @returns {Promise<boolean>} True if the measurement system is imperial, otherwise false.
 */
async function isImperial() {
  await msalInstance.acquireTokenSilent(tokenRequest);
  const activeAccount = msalInstance.getActiveAccount();
  const extensionMeasurementSystem =
    activeAccount?.idTokenClaims?.extension_MeasurementSystem;

  return extensionMeasurementSystem === "imperial";
}

/**
 * Checks if the active account belongs to a physiotherapist.
 * @returns {Promise<boolean>} True if the user is a physiotherapist, otherwise false.
 */
async function isUserPhysio() {
  await msalInstance.acquireTokenSilent(tokenRequest);
  const activeAccount = msalInstance.getActiveAccount();
  const extensionCategory = activeAccount?.idTokenClaims?.extension_Category;

  return extensionCategory === UserCategories.Physio.valueOf();
}