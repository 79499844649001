export const ATHLETE_COUNT_LIMIT = 25;
export const ATHLETE_TRIAL_COUNT_LIMIT = 10;

export const UserTiers = {
    Free: "Free",
    Pro: "Pro",
    Elite: "Elite",
    Admin: "Admin",
    ProTrial: "Pro Trial",
    EliteTrial: "Elite Trial",
    Invalid: "Invalid"
}
export function getUserTier(tier) {
    switch (tier) {
        case -1: return UserTiers.Invalid
        case 1: return UserTiers.Free
        case 2: return UserTiers.Pro
        case 3: return UserTiers.Elite
        case 4: return UserTiers.ProTrial
        case 5: return UserTiers.EliteTrial
        case 99: return UserTiers.Admin

        default:
            return UserTiers.Invalid
    }
}

export function getUserTierId(tier) {
    switch(tier) {
        case UserTiers.Invalid: return -1
        case UserTiers.Free: return 1
        case UserTiers.Pro: return 2
        case UserTiers.Elite: return 3
        case UserTiers.ProTrial: return 4
        case UserTiers.EliteTrial: return 5
        case UserTiers.Admin: return 99

        default:
            return -1
    }
}

export function canUserDownloadCsv(tier) {
    return [UserTiers.EliteTrial, UserTiers.Elite, UserTiers.Admin].includes(tier);
}

export function doesUserHaveLimitedRegistrations(tier) {
    return [UserTiers.Free, UserTiers.ProTrial, UserTiers.Pro, UserTiers.EliteTrial].includes(tier);
}

export function getAthleteRegistrationLimit(tier){
    if([UserTiers.Free, UserTiers.ProTrial, UserTiers.EliteTrial].includes(tier)){
        return ATHLETE_TRIAL_COUNT_LIMIT;
    }else{
        return ATHLETE_COUNT_LIMIT;
    }
}

export function isUserAdmin(tier) {
    return UserTiers.Admin === tier;
}

export function isUserSubscriptionExpired(expirationDateString) {
    let today = new Date()
    let expirationDate = new Date(expirationDateString)
    return expirationDate <= today;
}

export function canUserSeeGraphs(tier){
    return tier !== UserTiers.Free;
}