import {loginRequest, msalInstance} from "@/msal/authConfig";
import {InteractionType} from "@azure/msal-browser";
import {useNavBarOptionsStore} from "@/stores/NavBarOptionsStore";

function closeNavBarHamburgerMenu() {
  const navbarStore = useNavBarOptionsStore();
  navbarStore.closeHamburger();
  navbarStore.closeDropdown();
}

export function registerGuard(router) {
  router.beforeEach(async (to) => {
    closeNavBarHamburgerMenu();
    if (to.meta.requiresAuth) {
      const request = {
        ...loginRequest,
        redirectStartPage: to.fullPath
      }
      const shouldProceed = await isAuthenticated(msalInstance, InteractionType.Redirect, request);
      return shouldProceed || '/login';
    }
    if (to.meta.requiresUnauth) {
      const shouldProceed = !(await isAuthenticated(msalInstance));
      return shouldProceed || '/dashboard';
    }
    return true;
  });
}

export async function isAuthenticated(instance, interactionType, loginRequest) {
  // If the application uses redirects for interaction, handleRedirectPromise must be called and awaited on each page load before determining if a user is signed in or not
  return instance.handleRedirectPromise().then(() => {
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      return true;
    }

    // User is not signed in and attempting to access protected route. Sign them in.
    if (interactionType === InteractionType.Popup) {
      return instance.loginPopup(loginRequest).then(() => {
        return true;
      }).catch(() => {
        return false;
      })
    } else if (interactionType === InteractionType.Redirect) {
      return instance.loginRedirect(loginRequest).then(() => {
        return true;
      }).catch(() => {
        return false;
      });
    }

    return false;
  }).catch(() => {
    return false;
  });
}